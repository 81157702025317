import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { formatContractDetails } from './format-contract-details/FormatContractDetails'; // this type is not full response only parts we need here
const typedFetch = async function (controller, token, query, pageSize) { if (pageSize === void 0) {
    pageSize = 30;
} const allData = { data: [], included: [] }; let currentPage = 1; let pageCount = 1; while (currentPage <= pageCount) {
    var _allData$data, _allData$included;
    query.set('page[number]', currentPage.toString());
    query.set('page[size]', pageSize.toString());
    const response = await fetch(`https://api.teamtailor.com/v1/jobs?${query.toString()}`, { method: 'GET', headers: { 'X-API-Version': '20210218', Authorization: `Token token=${token}`, Accept: 'application/vnd.api+json', 'Content-Type': 'application/vnd.api+json' }, signal: controller.signal });
    if (controller.signal.aborted) {
        throw new Error('Aborted');
    }
    const { data, included, meta } = await response.json();
    (_allData$data = allData.data).push.apply(_allData$data, _toConsumableArray(data));
    (_allData$included = allData.included).push.apply(_allData$included, _toConsumableArray(included));
    pageCount = meta['page-count'];
    currentPage++;
} return allData; };
export const getDataFromApi = async (controller) => { const token = 'DqKlK0_W4F0T1qIRdkZDK-N3h2cDW6fACOb5MI_Q'; const query = new URLSearchParams(); query.append('include', 'locations,role'); const { data, included } = await typedFetch(controller, token, query); const offers = data.map(_ref => { var _relationships$locati; let { relationships, attributes, links } = _ref; const locations = ((_relationships$locati = relationships.locations) === null || _relationships$locati === void 0 ? void 0 : _relationships$locati.data.map(_ref2 => { let { id } = _ref2; return included.find(i => i.id === id); })) || []; const location = locations.map(loc => loc === null || loc === void 0 ? void 0 : loc.attributes.city).filter((loc, i, arr) => arr.indexOf(loc) === i); if (attributes['remote-status'] === 'fully') {
    location.push('Remote');
} const role = included.find(_ref3 => { var _relationships$role, _relationships$role$d; let { id } = _ref3; return ((_relationships$role = relationships.role) === null || _relationships$role === void 0 ? void 0 : (_relationships$role$d = _relationships$role.data) === null || _relationships$role$d === void 0 ? void 0 : _relationships$role$d.id) === id; }) || { attributes: { name: '' } }; return { title: attributes.title, link: links['careersite-job-url'], location, role: role.attributes.name, contractDetails: formatContractDetails(attributes.pitch || '') }; }); return offers; };
