// extracted by mini-css-extract-plugin
export var blockDescription = "careers-header-module--block-description--a6770";
export var blockText = "careers-header-module--block-text--ae8da";
export var descriptionContent = "careers-header-module--description-content--f4781";
export var descriptionsContainer = "careers-header-module--descriptions-container--eb65e";
export var imageContainer = "careers-header-module--image-container--e489b";
export var innerWrapper = "careers-header-module--inner-wrapper--58dae";
export var textAboveTitle = "careers-header-module--text-above-title--62714";
export var textContainer = "careers-header-module--text-container--999a6";
export var title = "careers-header-module--title--6f3fb";
export var titleImageContainer = "careers-header-module--title-image-container--a4f01";