// extracted by mini-css-extract-plugin
export var col1 = "about-our-space-module--col-1--c6f79";
export var col2 = "about-our-space-module--col-2--b76c6";
export var col3 = "about-our-space-module--col-3--1445d";
export var flex = "about-our-space-module--flex--a6266";
export var flip = "about-our-space-module--flip--6c5af";
export var flipBack = "about-our-space-module--flip-back--47967";
export var flipFront = "about-our-space-module--flip-front--aafeb";
export var numbersGrid = "about-our-space-module--numbers-grid--09271";
export var photoGrid = "about-our-space-module--photo-grid--ea43c";
export var section = "about-our-space-module--section--e79bc";
export var space1 = "about-our-space-module--space-1--352b3";
export var space2 = "about-our-space-module--space-2--4e867";
export var space3 = "about-our-space-module--space-3--c1512";
export var space4 = "about-our-space-module--space-4--98bfb";
export var space5 = "about-our-space-module--space-5--2a460";
export var space6 = "about-our-space-module--space-6--570d8";
export var space7 = "about-our-space-module--space-7--4d64a";