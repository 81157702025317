// extracted by mini-css-extract-plugin
export var applyButton = "job-offer-card-module--apply-button--ff5dc";
export var cardContainer = "job-offer-card-module--card-container--0a9ff";
export var category = "job-offer-card-module--category--c72d7";
export var contractDetails = "job-offer-card-module--contract-details--b28b5";
export var contractTypeDetails = "job-offer-card-module--contract-type-details--fd872";
export var jobTitle = "job-offer-card-module--job-title--8b0c3";
export var location = "job-offer-card-module--location--3bb80";
export var locationName = "job-offer-card-module--location-name--8918b";
export var locationsContainer = "job-offer-card-module--locations-container--4111d";
export var mainData = "job-offer-card-module--main-data--55f72";
export var offerDetails = "job-offer-card-module--offer-details--2a7ce";
export var salary = "job-offer-card-module--salary--5b988";
export var singleContractDetail = "job-offer-card-module--single-contract-detail--583fa";