// extracted by mini-css-extract-plugin
export var employeeData = "slider-with-employees-opinions-module--employee-data--9b7ad";
export var mainTitle = "slider-with-employees-opinions-module--main-title--2e968";
export var name = "slider-with-employees-opinions-module--name--51ca1";
export var opinion = "slider-with-employees-opinions-module--opinion--0c582";
export var outerWrapper = "slider-with-employees-opinions-module--outer-wrapper--86785";
export var photoImage = "slider-with-employees-opinions-module--photo-image--97fd3";
export var position = "slider-with-employees-opinions-module--position--3ad4e";
export var section = "slider-with-employees-opinions-module--section--3705e";
export var slide = "slider-with-employees-opinions-module--slide--5b8e4";
export var sliderCard = "slider-with-employees-opinions-module--slider-card--0b7e2";
export var sliderContent = "slider-with-employees-opinions-module--slider-content--25999";